<h1 class="mat-h1">Profile Information</h1>
<form [formGroup]="userForm">
  <mat-card>
    <mat-card-title>User Name</mat-card-title>
    <mat-card-content class="flex-container">
      <mat-form-field class="item-username">
        <input
          matInput
          placeholder="Email"
          formControlName="username"
          [readonly]="true"
        />
      </mat-form-field>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title> Name Information </mat-card-title>
    <mat-card-content class="flex-container">
      <mat-form-field class="item">
        <input matInput placeholder="First Name" formControlName="first_name" />
      </mat-form-field>
      <mat-form-field class="item">
        <input
          matInput
          placeholder="Last Name"
          formControlName="last_name"
          required
        />
        <mat-error
          *ngIf="
            userForm.get('last_name').invalid &&
            userForm.get('last_name').touched
          "
          >Last Name is required.
        </mat-error>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <!-- Phone Numbers -->
  <form [formGroup]="phoneNumbers">
    <mat-card>
      <mat-card-title>Phone Numbers</mat-card-title>
      <mat-card-content>
        <div formArrayName="phoneNumbers">
          <div
            *ngFor="let item of phoneNumbersForm.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="flex-container-nowrap">
              <a
                mat-icon-button
                class="icon-button"
                color="warn"
                (click)="onDeletePhoneNumberItem(i)"
              >
                <mat-icon>remove_circle_outline</mat-icon>
              </a>
              <div class="phoneNumbers">
                <app-phone-form
                  [phoneNumbersForm]="item"
                  [phoneTypes]="phoneTypes"
                ></app-phone-form>
              </div>
            </div>
          </div>
        </div>
        <a
          mat-icon-button
          class="icon-button"
          color="primary"
          (click)="onAddPhoneNumberItem()"
        >
          <mat-icon>add_circle_outline</mat-icon>
        </a>
      </mat-card-content>
    </mat-card>
  </form>

  <ng-container *ngIf="roles.includes('user_attorney')">
    <!-- Email setting -->
    <form [formGroup]="emailSettingForm">
      <mat-card>
        <mat-card-title>Email Settings</mat-card-title>
        <mat-card-content>
          <div class="flex-container-nowrap">
            <div style="width: 100%;">
              <app-email-setting-form
                [setting]="emailSettingForm.value"
                (caseUpdateAlerts)="onToggleCaseUpdateAlerts($event)"
              ></app-email-setting-form>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </form>
  </ng-container>

  <button
    mat-stroked-button
    type="button"
    color="primary"
    (click)="onSave()"
    routerLink="/clients"
    [disabled]="!userForm.valid"
  >
    SAVE
  </button>
  <button
    mat-stroked-button
    type="button"
    color="warn"
    routerLink="/change-password"
  >
    CHANGE PASSWORD
  </button>
</form>

<!-- <pre>{{ userForm.value | json }}</pre> -->
<!-- <pre>{{ phoneNumbers.value | json }}</pre> -->
<!-- <pre>{{ user | json }}</pre> -->
