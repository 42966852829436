import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  OnChanges,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import * as _ from 'lodash';
import {EmailSettingsFacade} from '@app/email-settings/+state';

@Component({
  selector: 'app-profile-ui',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnChanges {
  @Input() user = null;
  @Input() phoneTypes: any;
  @Input() statusUpdate: any;

  @Input() roles: any;

  @Output() update: EventEmitter<any> = new EventEmitter();

  @Output() phoneNumberCreate: EventEmitter<any> = new EventEmitter();
  @Output() phoneNumberUpdate: EventEmitter<any> = new EventEmitter();
  @Output() phoneNumberDelete: EventEmitter<any> = new EventEmitter();

  @Output() caseUpdateAlertsToggle: EventEmitter<any> = new EventEmitter();

  userForm: FormGroup;
  phoneNumbers: FormGroup;
  emailSettingForm: FormGroup;
  caseUpdateAlerts: boolean;

  phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

  constructor(private fb: FormBuilder) {
    this.createForms();
  }

  ngOnChanges(): void {
    if (this.user && this.userForm) {
      this.userForm.patchValue(this.user);

      if (this.user.user_phone_numbers) {
        this.phoneNumbers.patchValue(this.user.user_phone_numbers);
        this.displayPhoneNumbers();
      }
      if (this.statusUpdate) {
        this.emailSettingForm.patchValue(this.statusUpdate.body);
      }
    }
  }

  createForms() {
    this.userForm = this.fb.group({
      id: '',
      username: '',
      first_name: '',
      last_name: ['', Validators.required],
      enabled: '',
      last_login: '',
    });

    this.phoneNumbers = this.fb.group({
      phoneNumbers: this.fb.array([]),
    });

    this.emailSettingForm = this.fb.group({
      email: false,
      app: true
    });
  }

  ////////////////////////////////////
  // Phone Numbers Form
  ////////////////////////////////////
  get phoneNumbersForm() {
    return this.phoneNumbers.get('phoneNumbers') as FormArray;
  }

  displayPhoneNumbers() {
    for (let i = this.phoneNumbersForm.length; i >= 0; i--) {
      this.onDeletePhoneNumberItem(i);
    }

    this.user.user_phone_numbers.forEach((phone) => {
      this.onAddPhoneNumberItem(phone.phone_number);
    });
  }

  onAddPhoneNumberItem(phoneNumber?: any) {
    const newNumber = this.fb.group({
      __typename: phoneNumber ? phoneNumber.__typename : '',
      id: [phoneNumber ? phoneNumber.id : ''],
      type: [phoneNumber ? phoneNumber.type : 'work', Validators.required],
      number: [
        phoneNumber ? phoneNumber.number : '',
        [Validators.required, Validators.pattern(this.phoneRegex)],
      ],
    });

    this.phoneNumbersForm.push(newNumber);
  }

  onDeletePhoneNumberItem(i: number) {
    this.phoneNumbersForm.removeAt(i);
  }

  /**
   * onSave() - Persist all changes to the following entities
   *
   * 1. Client
   * 2. Phone Numbers
   *
   * For the User: check if the entity is modified then update.
   *
   * For the Phone Number Objects:
   *
   * Create: if the form's array element doesn't exist in the Object.
   * Update: if the form's array element is different than the element in the Object
   * Delete: if an object element exists but does not exist on the form.
   */
  onSave() {

    // User
    // Force an update to notify user that the profile info was saved.
    this.update.emit(this.userForm.value);

    ////////////////////////////////////////////////////////////
    // Phone Numbers
    //
    //  1.  Iterate over the Phone Numbers Form and check if the
    //      phone number is new or has been modified.
    //
    //  2.  Iterate over the original array of phone numbers
    //      and delete any that don't exist in the form.
    //
    ////////////////////////////////////////////////////////////
    const phoneNumbers = this.user.user_phone_numbers
      ? this.user.user_phone_numbers.map(
          (phoneNumber) => phoneNumber.phone_number
        )
      : [];
    const phoneNumberChanges =
      this.phoneNumbersForm.dirty ||
      this.phoneNumbersForm.value.length !== phoneNumbers.length;
    if (phoneNumberChanges) {
      // Iterate over the form's phone numbers:
      // 1. If the phone number doesn't exist or isn't the same => upsert
      const numbers = this.phoneNumbersForm.value;
      numbers.map((phoneNumber) => {
        const found = phoneNumbers.find((ph) => ph.id === phoneNumber.id);
        const isEqual = _.isEqual(phoneNumber, found);
        // If the object isn't found or the object in the form is different
        // from the object in the original array => UPSERT
        if (!found) {
          this.phoneNumberCreate.emit({
            user_id: this.user.id,
            phoneNumber,
          });
        } else if (found && !isEqual) {
          if (phoneNumber.hasOwnProperty('__typename')) {
            delete phoneNumber.__typename;
          }
          this.phoneNumberUpdate.emit(phoneNumber);
        }
      });
      // Iterate over the original array of phone numbers:
      // 2. If the phone number in the array doesn't exist
      //    in the form => DELETE
      phoneNumbers.map((phoneNumber) => {
        const found = numbers.find((e) => e.id === phoneNumber.id);
        if (!found) {
          this.phoneNumberDelete.emit(phoneNumber);
        }
      });
    }
    ////////////////////////////////////////////////////////////
    // Case Update Alerts
    this.caseUpdateAlertsToggle.emit(this.caseUpdateAlerts);
  }

  onToggleCaseUpdateAlerts(value) {
    this.caseUpdateAlerts = value;
  }
}
