import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, withLatestFrom } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AuthFacade } from '@auth/+state';
import * as fromActions from './preferences.actions';
import * as fromReducer from './preferences.reducer';
import * as fromSelectors from './preferences.selector';

@Injectable({ providedIn: 'root' })
export class PreferencesFacade {
  constructor(
    private store: Store<fromReducer.State>,
    private auth: AuthFacade
  ) {}

  getPreferenceByType$(type: string): Observable<any> {
    return this.store.pipe(select(fromSelectors.selectPreferenceByType(type)));
  }

  savePreferenceByType(type: string, body: any) {
    this.getPreferenceByType$(type)
      .pipe(withLatestFrom(this.auth.uid$), first())
      .subscribe(([preference, userId]) => {
        if (preference.__typename === 'default_preferences') {
          this.insertPreference(type, body, userId);
        } else {
          this.updatePreference(preference.id, body);
        }
      });
  }

  insertPreference(type: string, body: string, userId: string) {
    const input: any = {
      object: {
        user_id: userId,
        preference: {
          data: {
            type,
            body,
          },
        },
      },
    };
    this.store.dispatch(fromActions.insertPreference({ input }));
  }

  updatePreference(id: string, body: string) {
    const input: any = {
      id,
      body,
    };
    this.store.dispatch(fromActions.updatePreference({ input }));
  }

  // Load preferences on request
  loadPreferences() {
    this.store.dispatch(fromActions.loadPreference());
  }
}
