export const environment = {
    production: false,
    environment: 'integrations',
    firebaseConfig: {
        apiKey: "AIzaSyCtPQUQWrc5KsPC8nMeK-c_-eU7OE92GyA",
        authDomain: "sharescapeio-integrations.firebaseapp.com",
        projectId: "sharescapeio-integrations",
        storageBucket: "sharescapeio-integrations.appspot.com",
        messagingSenderId: "1018768859468",
        appId: "1:1018768859468:web:e8c24087603448fe486fe9"
    },
    hasura: {
        https: 'https://integrations-api.sharescape.io/v1/graphql',
        wss: 'wss://integrations-api.sharescape.io/v1/graphql',
    },
    apiUrl: 'https://integrations-api.sharescape.io',
    logToConsole: false,
};
