import { Injectable } from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  filter,
  withLatestFrom,
} from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthFacade } from '@auth/+state';
import { PreferencesService } from '../services/preferences.service';
import { setThemeName, initialState } from '@core/+state';
import { PreferencesFacade } from './preferences.facade';
import { LoggerService } from '@app/core/services';
import * as fromActions from './preferences.actions';
import * as themeStorage from '@core/+state/core.storage';
@Injectable()
export class PreferencesEffects {
  @Effect()
  loadPreferences$: Observable<Action> = combineLatest([
    this.auth.isAuthenticated$,
  ]).pipe(
    filter(([authenticated]) => authenticated),
    switchMap(() => {
      return this.preferencesService.gePreferences().pipe(
        map((preferences) => fromActions.loadSuccess({ preferences })),
        catchError((error) => of(fromActions.loadError({ error })))
      );
    })
  );

  // Load the Preference on Request
  @Effect()
  onRequestLoadPreferences$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.loadPreference),
    switchMap(() => {
      return this.preferencesService.gePreferences().pipe(
        map((preferences) => fromActions.loadSuccess({preferences})),
        catchError((error) => of(fromActions.loadError({error})))
      );
    })
  );

  @Effect({ dispatch: false })
  loadPreferencesError$ = this.actions$.pipe(
    ofType(fromActions.loadError),
    map(({ error }) => {
      const message = 'An error occurred loading Preferences.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  insertPreference$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.insertPreference),
    switchMap(({ input }) => {
      return this.preferencesService.insert(input).pipe(
        map((preference) => fromActions.insertPreferenceSuccess(preference)),
        catchError((error) => of(fromActions.insertPreferenceError({ error })))
      );
    })
  );

  @Effect({ dispatch: false })
  insertPreferenceError$ = this.actions$.pipe(
    ofType(fromActions.insertPreferenceError),
    map(({ error }) => {
      const message = 'An error occurred saving a new preference.';
      this.logger.error(message, error);
    })
  );

  @Effect()
  updatePreference$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.updatePreference),
    switchMap(({ input }) => {
      return this.preferencesService.update(input).pipe(
        map((preference) =>
          fromActions.updatePreferenceSuccess({ preference })
        ),
        catchError((error) => of(fromActions.updatePreferenceError({ error })))
      );
    })
  );

  @Effect({ dispatch: false })
  updatePreferenceError$ = this.actions$.pipe(
    ofType(fromActions.updatePreferenceError),
    map(({ error }) => {
      const message = 'An error occurred saving preference.';
      this.logger.error(message, error);
    })
  );

  // Set Theme from ThemeName Preference value
  @Effect()
  setThemePreferences$: Observable<Action> = this.actions$.pipe(
    ofType(fromActions.loadSuccess),
    withLatestFrom(this.preferences.getPreferenceByType$('SelectedThemeName')),
    map(([action, selectedThemePreference]) => {
      let theme = selectedThemePreference.body;
      const pref = action.preferences.user.find(
        (p) => p.preference.type === 'SelectedThemeName'
      );
      if (typeof pref !== 'undefined') {
        theme = pref.preference.body.themeName;
      }
      const themeName = themeStorage.validateTheme(theme);
      const isDark = themeStorage.isDarkTheme(themeName);
      return setThemeName({ themeName, isDark });
    })
  );

  @Effect()
  logout$: Observable<Action> = this.auth.isAuthenticated$.pipe(
    filter((authenticated) => !authenticated),
    map(() => fromActions.clearState())
  );

  constructor(
    private actions$: Actions,
    private auth: AuthFacade,
    private preferences: PreferencesFacade,
    private preferencesService: PreferencesService,
    private logger: LoggerService
  ) {}
}
