import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  AfterViewInit,
  OnDestroy, OnInit,
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil, map} from 'rxjs/operators';
import {MatSort, Sort} from '@angular/material/sort';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Screen} from '@core/models';

@Component({
    selector: 'app-clients-list',
    templateUrl: './clients-list.component.html',
    styleUrls: ['./clients-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientsListComponent
    implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;

    destroy$: Subject<boolean> = new Subject<boolean>();

    // Actions
    @Input() clientEdit: boolean;
    @Input() clientDetails: boolean;
    @Input() clientShare: boolean;
    @Input() clientRefresh: boolean;
    @Input() clientDelete: boolean;

    @Input() isClientMerge: boolean;
    @Input() selectedMergeClients: any;

    @Input() screen: Screen;
    @Input() displayedColumns = [];
    @Input() clients: any[] = [];
    @Input() selectedClient: any = null;

    @Input() pageSize: number;
    @Input() clientsListTableInformation: PageEvent = null;
    @Input() clientsListTableSort: Sort = null;
    @Input() count = 0;

    @Input() roles = [];
    @Input() selectCaseStatuses: any;

    @Output() selectItem: EventEmitter<any> = new EventEmitter();
    @Output() edit: EventEmitter<any> = new EventEmitter();
    @Output() details: EventEmitter<any> = new EventEmitter();
    @Output() share: EventEmitter<any> = new EventEmitter();
    @Output() update: EventEmitter<any> = new EventEmitter();
    @Output() tableInformation: EventEmitter<PageEvent> = new EventEmitter();
    @Output() pageSizeChanged: EventEmitter<number> = new EventEmitter();
    @Output() tableSort: EventEmitter<Sort> = new EventEmitter();
    @Output() import: EventEmitter<void> = new EventEmitter();
    @Output() refresh: EventEmitter<any> = new EventEmitter();
    @Output() selectMergeClient: EventEmitter<{ value, data }> = new EventEmitter();
    @Output() merge: EventEmitter<any> = new EventEmitter();
    @Output() cancelMerge: EventEmitter<any> = new EventEmitter();
    @Output() updateStatus: EventEmitter<any> = new EventEmitter();

    dataSource = new MatTableDataSource<any>();

  finalizationRequest = 'Finalization Request';
  noLongerRepresented = 'No Longer Represented';
  closedNoRecovery = 'Closed – No Recovery';
  reductionNeeded = 'Reduction Needed';
  tooltipText = '';

  ngOnChanges() {
    if (this.clients) {
      this.dataSource.data = this.clients;
    }
    // this.showProviderColumn();
    // display statuses alert column
    this.showCaseStatusAlert();
    if (this.isClientMerge) {
      this.mergeSelection();
    } else {
      this.cancelMergeSelection();
    }
  }

  mergeSelection() {
    if (this.displayedColumns && !this.displayedColumns.includes('check')) {
      const displayedColumn = [...this.displayedColumns];
      displayedColumn.unshift('check');
      this.displayedColumns = displayedColumn;
    }
  }

  cancelMergeSelection() {
    if (this.displayedColumns && this.displayedColumns.includes('check')) {
      let displayedColumn = [...this.displayedColumns];
      displayedColumn = displayedColumn.filter(c => c !== 'check');
      this.displayedColumns = displayedColumn;
    }
  }

  showProviderColumn() {
    // If logged in as attorney then show Provider column on the Clients List
    if (this.roles?.includes('user_attorney') &&
      this.displayedColumns &&
      !this.displayedColumns.includes('provider')) {
      const displayedColumn = [...this.displayedColumns];
      const index = displayedColumn.indexOf('sharedOrganizations');
      displayedColumn.splice(index, 1, 'provider');
      this.displayedColumns = [...displayedColumn];
    }
  }

  showCaseStatusAlert() {
    if (this.displayedColumns && this.displayedColumns.includes('statuses') &&
      this.roles.length > 0) {
      const displayedColumn = [...this.displayedColumns];
      if (this.roles.includes('user_attorney') &&
        !this.displayedColumns.includes('attorney-status')) {
        displayedColumn.splice((this.displayedColumns.length - 1), 0, 'attorney-status');
      }
      if (!this.roles.includes('user_attorney') &&
        !displayedColumn.includes('provider-status')) {
        displayedColumn.unshift('provider-status');
      }
      this.displayedColumns = [...displayedColumn];
    }
  }

  ngOnInit(): void {
  }


  ngAfterViewInit() {
    if (this.sort) {
      this.sort.sortChange
        .pipe(
          takeUntil(this.destroy$),
          map((sortInformation) => this.tableSort.emit(sortInformation))
        )
        .subscribe();
      this.paginator.page
        .pipe(
          takeUntil(this.destroy$),
          map((val) => {
            if (val.pageSize !== this.clientsListTableInformation.pageSize) {
              this.pageSizeChanged.emit(val.pageSize);
            }
            this.tableInformation.emit(val);
          })
        )
        .subscribe();
    }
  }

  onChangeStatus(clientId, statudId) {
    const input = {client_id: clientId, status_id: statudId};
    this.updateStatus.emit(input);
  }

  getTooltipText(value, attorney) {
    switch (value) {
      case this.finalizationRequest: {
        attorney ?
          this.tooltipText =
            'We\'ll send an email to the provider letting them know you need all bills and records uploaded to ShareScape' :
          this.tooltipText =
            'The law firm needs all bills and records uploaded to ShareScape as soon as possible!';
        break;
      }
      case this.reductionNeeded: {
        attorney ?
          this.tooltipText =
            'Please reach out to the medical provider to discuss the reduction needed' :
          this.tooltipText =
            'Please reach out to the law firm to discuss the reduction needed';
        break;
      }
      default: {
        this.tooltipText = value;
        break;
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
