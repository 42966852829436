<div *ngIf="screen && clientsListTableInformation && clientsListTableSort">
  <mat-table
    matSort
    matSortDisableClear
    [dataSource]="dataSource"
    [matSortActive]="clientsListTableSort.active"
    [matSortDirection]="clientsListTableSort.direction"
  >
    <!-- Account Number -->
    <!-- <ng-container matColumnDef="accountNo">
    <mat-header-cell
      *matHeaderCellDef
      [ngClass]="{
        'hide-column': screen.device === 'handset' && screen.portrait
      }"
    >
      Acct No
    </mat-header-cell>
    <mat-cell
      *matCellDef="let row"
      [ngClass]="{
        'hide-column': screen.device === 'handset' && screen.portrait
      }"
      >{{ row | metadata: 'accountNumber' }}</mat-cell
    >
  </ng-container> -->

      <!-- Check -->
    <ng-container *ngIf="isClientMerge" matColumnDef="check">
      <mat-header-cell
         *matHeaderCellDef
         [ngClass]="{ 'hide-column': screen.device === 'handset' }"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{ 'hide-column': screen.device === 'handset' }">
          <mat-checkbox [checked]="selectedMergeClients?.ids.includes(row.id)" (change)="selectMergeClient.emit({value: $event.checked, data: row})"></mat-checkbox>
      </mat-cell>
    </ng-container>
      <!-- Last Name -->
      <ng-container matColumnDef="lastName">
          <mat-header-cell
                  *matHeaderCellDef
                  mat-sort-header
                  [ngClass]="{ 'hide-column': screen.device === 'handset' }"
          >
              Last Name
          </mat-header-cell>
          <mat-cell
                  *matCellDef="let row"
                  [ngClass]="{ 'hide-column': screen.device === 'handset' }">
              {{row.last_name}}
          </mat-cell>
      </ng-container>

    <!-- First Name -->
    <ng-container matColumnDef="firstName">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{ 'hide-column': screen.device === 'handset' }"
      >
        First Name
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{ 'hide-column': screen.device === 'handset' }"
        >{{ row.first_name }}</mat-cell
      >
    </ng-container>

    <!-- Name -> displayed only on Mobile -->
    <ng-container matColumnDef="name">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{ 'hide-column': screen.device !== 'handset' }"
        >Name</mat-header-cell
      >
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{ 'hide-column': screen.device !== 'handset' }"
        >{{ row.last_name }}, {{ row.first_name }}
      </mat-cell>
    </ng-container>

    <!-- Shared Organization -->
    <ng-container matColumnDef="sharedOrganizations">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Shared Organization
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ row?.shared_organizations | organizationNames}}
      </mat-cell>
    </ng-container>

    <!-- Provider Organization -->
    <ng-container matColumnDef="provider">
      <mat-header-cell
        *matHeaderCellDef
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Provider
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >
        {{ row.provider ?
        (row.provider | organizationNames) :
        (row.providers | providerNames) }}
      </mat-cell>
    </ng-container>

    <!-- Date of Birth -->
    <ng-container matColumnDef="dateOfBirth">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Date of Birth
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ row.date_of_birth | dateValidator: 'MM/dd/yyyy' }}</mat-cell
      >
    </ng-container>

    <!-- Date of Injury -->
    <ng-container matColumnDef="dateOfInjury">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Date of Injury
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{ (row.date_of_injury | dateValidator: 'MM/dd/yyyy') }}</mat-cell
      >
    </ng-container>

    <!-- Balance -->
    <ng-container matColumnDef="balance">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
      >
        Balance
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{
          'hide-column': screen.device === 'handset' && screen.portrait
        }"
        >{{
          row.balance !== undefined ?
            (row.balance | currency: 'USD':'symbol' | customCurrency) :
            (row?.client_balance?.balance | currency: 'USD':'symbol' | customCurrency)
        }}</mat-cell
      >
    </ng-container>

    <!-- Work Phone -->
    <ng-container matColumnDef="workPhone">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Work
      </mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.workPhone }} </mat-cell>
    </ng-container>


    <!-- Provider status Alert -->
    <ng-container matColumnDef="provider-status">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{ 'hide-column': screen.device === 'handset'  && screen.portrait}"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}">
        <span>
          <mat-icon class="warning-icon"
                    *ngIf="row.client_status?.status_value == finalizationRequest">
            warning
          </mat-icon>
          <mat-icon class="danger-icon"
                    *ngIf="row.client_status?.status_value == noLongerRepresented ||
                    row.client_status?.status_value == closedNoRecovery">
            warning
          </mat-icon>
        </span>
      </mat-cell>
    </ng-container>

    <!-- Attorney status Alert -->
    <ng-container matColumnDef="attorney-status">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{ 'hide-column': screen.device === 'handset'  && screen.portrait}"
      >
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}">
        <span>
          <mat-icon class="warning-icon"
                    *ngIf="row?.client_status?.needs_update">
            warning
          </mat-icon>
        </span>
      </mat-cell>
    </ng-container>

    <!-- Case_status -->
    <ng-container matColumnDef="statuses">
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{ 'hide-column': screen.device === 'handset'  && screen.portrait}"
      >
        Case Status
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"

        matTooltip="{{tooltipText}}"
        (mouseover)="getTooltipText(row?.client_status?.status_value, roles.includes('user_attorney'))"
        [ngClass]="{ 'hide-column': screen.device === 'handset'  && screen.portrait}">

        <button mat-icon-button [autofocus]="false"
                [matMenuTriggerFor]="status"
                [ngClass]="{ 'disableStatusMenu' : !roles.includes('user_attorney') }">

          <span *ngIf="roles.includes('user_attorney');then attorney else provider"></span>

          <ng-template #attorney>
           <span class="status-value" [ngClass]="{'warning-text': row.client_status?.needs_update}">
            {{row?.client_status?.needs_update ? 'Needs Case Update' : row.client_status?.status_value}}
          </span>
          </ng-template>

          <ng-template #provider>
            <span class="status-value"
                  [ngClass]="{'warning-text': row.client_status?.status_value == finalizationRequest ,
                              'danger-text': row.client_status?.status_value == noLongerRepresented ||
                               row.client_status?.status_value == closedNoRecovery}">

            {{row.client_status?.status_value == finalizationRequest ?
              row.client_status?.status_value + '...' :
              row.client_status?.status_value}}

          </span>
          </ng-template>

          <mat-icon class="iconx" *ngIf="roles.includes('user_attorney')">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #status="matMenu">
          <button class="status" mat-menu-item
                  matTooltip="{{tooltipText}}"
                  (mouseover)="getTooltipText(status.value, roles.includes('user_attorney'))"
                  *ngFor="let status of selectCaseStatuses">

            <span>{{status.value}}</span>

            <mat-icon color="primary" (click)="onChangeStatus(row.id, status.id)">send</mat-icon>
            <!--            <mat-icon color="primary">note_add</mat-icon>-->
          </button>
        </mat-menu>
      </mat-cell>
    </ng-container>

    <!-- Actions: calculate width for the number of enabled actions-->
    <ng-container matColumnDef="actions">
      <mat-header-cell
        *matHeaderCellDef
        [ngStyle]="{
          'min-width.px':
            ((clientDetails ? 1 : 0) +
              (clientEdit ? 1 : 0) +
              (clientRefresh ? 1 : 0) +
              (clientShare ? 1 : 0)) *
            40
        }"
      >
        Actions
      </mat-header-cell>
      <mat-cell
        *matCellDef="let row"
        [ngStyle]="{
          'min-width.px':
            ((clientDetails ? 1 : 0) +
              (clientEdit ? 1 : 0) +
              (clientRefresh ? 1 : 0) +
              (clientShare ? 1 : 0)) *
            40
        }"
      >
        <button
          *ngIf="clientDetails"
          type="button"
          mat-icon-button
          color="primary"
          (click)="details.emit(row)"
          matTooltip="Details"
          [matTooltipShowDelay]="750"
        >
          <mat-icon>details</mat-icon>
        </button>
        <button
          *ngIf="clientEdit"
          type="button"
          mat-icon-button
          color="primary"
          matTooltip="Edit"
          (click)="edit.emit(row)"
          [matTooltipShowDelay]="750"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          *ngIf="clientShare"
          type="button"
          mat-icon-button
          color="primary"
          matTooltip="Share"
          (click)="share.emit(row)"
          [matTooltipShowDelay]="750"
        >
          <mat-icon>share</mat-icon>
        </button>
        <button
          *ngIf="clientRefresh"
          type="button"
          mat-icon-button
          color="accent"
          (click)="refresh.emit(row)"
          matTooltip="Refresh"
          [matTooltipShowDelay]="750"
        >
          <mat-icon>refresh</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="selectItem.emit(row)"
      matRipple
      [ngClass]="{ selected: selectedClient && selectedClient.id === row.id}"
    >
    </mat-row>
  </mat-table>
    <div class="table-footer">
        <ng-container *ngIf="isClientMerge">
            <button mat-stroked-button [disabled]="selectedMergeClients?.ids.length < 2"  (click)="merge.emit()">Merge</button>
            <button mat-stroked-button class="merge" (click)="cancelMerge.emit()">Cancel</button>
        </ng-container>
        <mat-paginator
                [showFirstLastButtons]="true"
                [pageSizeOptions]="[5, 10, 25, 50, 100]"
                [pageSize]="clientsListTableInformation.pageSize"
                [pageIndex]="clientsListTableInformation.pageIndex"
                [length]="count"
        ></mat-paginator>
    </div>
</div>
